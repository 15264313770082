<template>
  <v-app id="app">
    <v-main>
        <Navigation/>
        <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  components: {
    Navigation: () => import('./components/Navigation.vue'),
    Footer: () => import('./components/Footer.vue')
  }
}
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h3 {
    font-weight: bold;
    font-size: 14px;
    color: #2c3e50;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
