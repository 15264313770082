import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Vuetify from 'vuetify';
import VueAnalytics from 'vue-analytics'

Vue.use(Vuetify);
Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-219082566-1',
  router,
  checkDuplicatedScript: true
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
